@import 'core/variables';
@import 'core/screen-sizes';
@import 'theming/functions';
@import 'mixins';

$border-color: app-color(border-color);
$border-color-addit-2: app-color(border-color-additional-2);
$danger-color: app-color(danger-color);
$bg-additional-color: app-color(bg-additional-color);
$bg-main-color: app-color(bg-main-color);
$bg-extra-color: app-color(bg-extra-color);
$bg-block-main-color: app-color(bg-block-main-color);
$link-text-color: app-color(text-color);
$link-text-color-50: app-color(text-color, 0.5);
$link-text-color-inverse: app-color(text-inverse-color);
$active-link-text-color: app-color(text-color);
$active-link-text-color-mobile: app-color(success-blue-2-color);
$bg-light-blue-addit: app-color(bg-light-blue-addit);

$nav-height: 36px;
$nav-height-mobile: 62px;

.mat-mdc-focus-indicator {
  position: relative;
}

.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator {
  &:not(:last-child) {
    //border-right: 1px solid $border-color-addit-2;
  }
}

.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
  //display: none !important;
  min-width: 16px;
  width: 16px;
  padding: 0;
}

.mat-mdc-tab-nav-bar {
  background-color: $bg-block-main-color !important;

  .mat-ripple,
  .mdc-tab__ripple,
  .mdc-tab-indicator {
    display: none;
  }

  .mat-mdc-tab-links {
    height: $nav-height;
    justify-content: space-between;
    padding: 0 2px;

    .mat-mdc-tab-link {
      height: auto;
      padding: 0;
      min-width: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.--bottom-nav {
    background-color: $bg-extra-color !important;

    .mat-mdc-tab-links {
      height: $nav-height-mobile;
    }

    .mat-mdc-tab-link {
      .mdc-tab__text-label {
        color: $link-text-color-inverse;
      }

      &.active {
        .mdc-tab__text-label {
          color: $active-link-text-color-mobile;
        }
      }
    }
  }

  &.--as-toggle {
    border: none !important;
    border-radius: 4px;
    padding: 2px 0;
    height: 48px;

    &.--mobile {
      height: 72px;
    }

    .mat-mdc-tab-links {
      height: 100%;

      .mat-mdc-tab-link {
        flex-basis: 0;
        flex-grow: 1;

        &.active {
          border-radius: 4px;
          background-color: $bg-main-color;
        }

        .mdc-tab__text-label {
          padding: 0 24px;
          height: 100%;
          color: $link-text-color;

          @include subtitle-primary-semibold-12-font-styles();
        }

        &.active .mdc-tab__text-label {
          color: $active-link-text-color;
        }
      }
    }
  }
}

.mat-mdc-tab-group {
  width: 100%;

  .mat-mdc-tab-body-content {
    &::-webkit-scrollbar {
      width: $scrollbar-size;
      height: $scrollbar-size;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $border-color;
    }
  }

  .mdc-tab__ripple,
  .mdc-tab-indicator,
  .mat-mdc-tab-ripple {
    display: none;
  }

  &.--snack {
    .mat-mdc-tab-header {
      border: none;

      .mat-mdc-tab-labels {
        gap: 24px;

        .mat-mdc-tab {
          height: 72px;
          //border-radius: 8px;
          background-color: $bg-main-color;
          //border: 1px $border-color solid;

          &.mdc-tab--active {
            //background-color: $bg-extra-color;
            //color: $link-text-color-inverse;
            border: none;
          }
        }
      }
    }

    &.--mobile {
      .mat-mdc-tab-labels {
        gap: 8px;

        .mat-mdc-tab {
          padding: 0;
        }
      }
    }
  }

  &.--as-toggle {
    .mat-mdc-tab-header {
      //border: none !important;
      border-radius: 4px;
      padding: 0;
      //height: 24px;
    }

    .mat-mdc-tab {
      padding: 0;

      .mdc-tab__text-label {
        padding: 0 8px !important;
      }

      //margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      .mdc-tab__text-label {
        height: 24px;
        border-radius: 4px;
      }

      &.mdc-tab--active {
        background-color: $light-primary;
      }

      &:not(.mdc-tab--active) {
        background-color: $bg-additional-color;
      }
    }
  }

  &.--as-tile {
    .mat-mdc-tab-header {
      border: none;

      .mat-mdc-tab-header-pagination-chevron {
        color: $link-text-color;
      }

      .mat-mdc-tab-label-container {
        .mat-mdc-tab-labels {
          gap: 12px;

          .mdc-tab {
            width: 100px;
            min-width: 100px;
            height: 100px;
            border: none;
            border-radius: 8px;
            padding: 0;

            &:not(&--active) {
              //color: $link-text-color;
              //background-color: $bg-main-color;
            }

            &--active {
              //color: $link-text-color-inverse;
              //background-color: $bg-extra-color;
            }
          }
        }
      }
    }

    &.--bordered-tile {
      .mat-mdc-tab-labels {
        gap: 8px !important;

        .mdc-tab {
          &:not(&--active) {
            border: 1px $border-color solid;
          }
        }
      }
    }
  }

  &.--as-tile-mobile {
    .mat-mdc-tab-header {
      border: none;

      .mat-mdc-tab-header-pagination-chevron {
        color: $link-text-color;
      }

      .mat-mdc-tab-label-container {
        .mat-mdc-tab-labels {
          gap: 8px;

          .mdc-tab {
            width: 40px;
            min-width: 40px;
            height: 40px;
            border: none;
            border-radius: 8px;
            padding: 0;

            &:not(&--active) {
              color: $link-text-color;
            }

            &--active {
              color: $link-text-color-inverse;
            }
          }
        }
      }
    }

    .mat-mdc-tab-body-wrapper {
      margin-top: 12px;
    }

    &__with-text {
    }
  }
}
