@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../../assets/fonts/Noto_Sans/regular/notosans-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/Noto_Sans/medium/notosans-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/fonts/Noto_Sans/semibold/notosans-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../../assets/fonts/Noto_Sans/bold/notosans-bold.ttf') format('truetype');
}
