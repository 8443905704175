@import 'theming/functions';

$danger-color: app-color(danger-color);

.mat-mdc-radio-button {
  .mdc-form-field {
    width: 100%;
    align-items: flex-start;
    border-radius: 8px;
    padding: 4px;

    .mdc-radio {
      padding: 0;
    }

    .mdc-label {
      //padding-left: 20px;
    }
  }

  &.mat-mdc-radio-checked {
    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      border-color: $danger-color !important;
    }
  }

  &.--mobile {
    .mdc-label {
      padding-left: 16px;
    }

    .mdc-radio__outer-circle {
      width: 16px;
      height: 16px;
    }

    .mdc-radio__inner-circle {
      width: 2px;
      height: 2px;
      border-width: 8px;
    }
  }
}
