@import 'theming/functions';

$border-color: app-color(danger-color);
$text-color: app-color(text-static-color);

.mdc-evolution-chip-set {
  &__chips {
    display: inline-block;
    min-width: 0 !important;

    .mat-mdc-chip.mat-mdc-chip-option {
      width: unset;
      height: 32px;
      border: 1px solid $border-color;
      border-radius: 16px;
      padding: 0 12px;

      &.mat-mdc-chip-selected.mdc-evolution-chip--selected {
        background-color: $border-color;

        .mdc-evolution-chip__text-label {
          color: $text-color;
        }
      }

      .mdc-evolution-chip__text-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $border-color;
      }

      .mdc-evolution-chip__graphic.mat-mdc-chip-graphic {
        display: none;
      }

      .mat-mdc-chip-remove {
        padding-right: 0 !important;
      }
    }

    .mdc-evolution-chip__action.mat-mdc-chip-action {
      padding-right: 0 !important;
    }
  }
}
