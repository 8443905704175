// @import './google-fonts';
@import 'custom-fonts';
@import 'mixins';

// base font styles

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-size: 14px;
  font-family: $font-family-primary !important;
}

input {
  &::shadow {
    div {
      font-family: $font-family-primary !important;
    }
  }
}

h1,
h2,
h3,
h4 {
  margin: 0; // не менять, рушит верстку
}

// heads

.heading-primary-bold-40 {
  @include heading-primary-bold-40-font-styles();
}

.heading-primary-bold-32 {
  @include heading-primary-bold-32-font-styles();
}

.heading-primary-semibold-32 {
  @include heading-primary-semibold-32-font-styles();
}

.heading-primary-medium-32 {
  @include heading-primary-medium-32-font-styles();
}

.heading-primary-bold-24 {
  @include heading-primary-bold-24-font-styles();
}

.heading-primary-semibold-24 {
  @include heading-primary-semibold-24-font-styles();
}

.heading-primary-medium-24 {
  @include heading-primary-medium-24-font-styles();
}

.heading-primary-regular-24 {
  @include heading-primary-regular-24-font-styles();
}

.heading-primary-bold-20 {
  @include heading-primary-bold-20-font-styles();
}

.heading-primary-semibold-20 {
  @include heading-primary-semibold-20-font-styles();
}

.heading-primary-medium-20 {
  @include heading-primary-medium-20-font-styles();
}

.heading-primary-regular-20 {
  @include heading-primary-regular-20-font-styles();
}

// subtitles

.subtitle-primary-bold-16 {
  @include subtitle-primary-bold-16-font-styles();
}

.subtitle-primary-semibold-16 {
  @include subtitle-primary-semibold-16-font-styles();
}

.subtitle-primary-medium-16 {
  @include subtitle-primary-medium-16-font-styles();
}

.subtitle-primary-regular-16 {
  @include subtitle-primary-regular-16-font-styles();
}

.subtitle-primary-bold-14 {
  @include subtitle-primary-bold-14-font-styles();
}

.subtitle-primary-semibold-14 {
  @include subtitle-primary-semibold-14-font-styles();
}

.subtitle-primary-medium-14 {
  @include subtitle-primary-medium-14-font-styles();
}

.subtitle-primary-regular-14 {
  @include subtitle-primary-regular-14-font-styles();
}

.subtitle-primary-bold-12 {
  @include subtitle-primary-bold-12-font-styles();
}

.subtitle-primary-semibold-12 {
  @include subtitle-primary-semibold-12-font-styles();
}

.subtitle-primary-medium-12 {
  @include subtitle-primary-medium-12-font-styles();
}

.subtitle-primary-regular-12 {
  @include subtitle-primary-regular-12-font-styles();
}

.subtitle-primary-bold-10 {
  @include subtitle-primary-bold-10-font-styles();
}

.subtitle-primary-semibold-10 {
  @include subtitle-primary-semibold-10-font-styles();
}

.subtitle-primary-medium-10 {
  @include subtitle-primary-medium-10-font-styles();
}

.subtitle-primary-regular-10 {
  @include subtitle-primary-regular-10-font-styles();
}

.app-text-link {
  cursor: pointer;
  text-decoration: underline;

  &--not-underline {
    text-decoration: none;
  }

  &--disabled {
    cursor: default;
    text-decoration: none;

    pointer-events: none;
  }
}

.app-text-truncate {
  @include text-truncate();
}

.app-text-capitalize {
  text-transform: capitalize;
}

.app-text-uppercase {
  text-transform: uppercase;
}

.app-text-lower {
  text-transform: lowercase;
}

.app-text-not-capitalize {
  text-transform: none;
}

.app-text-white-space-pre {
  white-space: pre;
}

.app-text-white-space-wrap {
  white-space: pre-wrap;
}

.app-text-white-space-nowrap {
  white-space: nowrap;
}

.app-text-first-letter-up {
  @include first-letter-up();
}

.app-text-center {
  text-align: center;
}

.app-text-start {
  text-align: start;
}

.app-text-end {
  text-align: end;
}

.app-text-right {
  text-align: right;
}
