// common
$success: #10a426;
$success-light: #0fac27;
$success-blue: #617aff;
$success-blue-2: #6eaef9;
$danger: #ff4058;
$warning: #ffb039;

// light
$light-primary: #ffffff;
$light-secondary: #f8f9fb;

// dark
$dark-primary: #000000;

// background
$light-main-bg: $light-primary;
$light-additional-bg: #383d4e;
$light-block-main-bg: #f5f5f5;
$light-block-addit-bg: #ededed;
$light-proprietary-bg: #686f89;
$light-proprietary-addit-bg: #383d4e;
$dark-block-main-bg: #374364;
$light-extra-bg: #495067;
$light-extra-3-bg: #495067;
$light-blue-addit-bg: #e9ecf8;
$light-blue-addit-2-bg: #e1edfa;
$light-blue-addit-3-bg: #fafaff;
$dark-main-bg: $dark-primary;

// text
$light-text-color: #383d4e;
$light-additional-text-color: #abb3ce;
$light-additional-2-text-color: #9e9e9e;
$light-extra-text-color: #afafaf;
$dark-extra-text-color: #afafaf;
$dark-text-color: $light-primary;

// elements
$light-border-color: #525b74;
$dark-border-color: $dark-primary;

// additional
$light-additional: #f4f6ff;
$light-additional-2: #e3e3e3;
$light-additional-3: #f0f0f0;
$light-additional-4: #eaeaea;
$light-additional-5: #e5e5ff;

$red: #f06565;
$soft-red: #f99c9c;
$blue: #617aff;
$soft-blue: #c8e2fd;
$soft-yellow: #fbead0;
$green: #23d580;
$purple: #a156ec;
$dark-purple: #763def;

$indigo: #b0c5f5;
$indigo-deep: #618ef3;
$blue-soft: #c8dff7;
$lightblue: #e5ecfc;
$teal: #8ac8bb;
$lightpurple: #cabdf6;
$pink: #f4caca;
$yellow: #f7f3c3;
$yellow-75: rgba(#f7f3c3, 0.75);
$bisque: #f2dabc;
$bisque-75: rgba(#f2dabc, 0.75);
$purple-soft: #d4b2dd;
$palegreen: #d4fdc6;
$cyan: #bef6ea;
$checked-color: #e0e1e1;
$whitesmoke: whitesmoke;

$shadow-color: rgba(0, 0, 0, 0.12);
