@import 'typography/mixins';
@import 'theming/functions';
@import 'core/variables';

$text-color: app-color(text-color);
$text-additional-2-color: app-color(text-additional-2-color);
$bg-additional-color: app-color(bg-additional-color);
$border-color: app-color(border-color);

.mat-expansion-panel {
  border-radius: 0 !important;
  box-shadow: none !important;
  color: $text-color;

  &.mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel-header {
    padding: 0;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  &.mobile {
    .mat-expansion-panel-body {
      padding: 0 0 16px;
    }
  }

  &.mat-expanded {
    //background-color: $bg-light-blue-addit;
  }

  &:not(.mat-expanded) {
    //border-bottom: 1px solid $border-color;
  }

  .mat-expansion-panel-header {
    height: 44px !important;
    padding: 0;
    color: $text-color;

    &[aria-disabled='true'] {
      color: $text-additional-2-color;
    }

    &:hover {
      //background-color: $bg-additional-color !important;
    }

    &.mat-expanded {
      margin-bottom: 8px;
    }
  }

  .mat-expansion-panel-content {
    color: $text-color;
  }

  .mat-expansion-panel-header-title {
    align-items: center;
    color: $text-color;
  }

  .mat-expansion-panel-header-description {
    justify-content: flex-end;
  }

  .mat-expansion-indicator {
    margin-right: 20px;

    &::after {
      color: $text-additional-2-color;
    }
  }
}
