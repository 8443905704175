.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;

  &:not(:empty) {
    .cdk-overlay-backdrop {
      background: rgba(0, 0, 0, 0.4);
    }

    &:has(.app-select-dropdown),
    &:has(.mat-mdc-menu-panel) {
      .cdk-overlay-backdrop {
        background: transparent;
      }
    }
  }

  .cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: auto;
  }

  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .cdk-overlay-pane {
    pointer-events: auto;
  }

  .cdk-overlay-connected-position-bounding-box {
    display: flex;
    position: absolute;
    flex-direction: column;

    .cdk-overlay-pane {
      position: absolute;
    }
  }

  .mdc-line-ripple::after {
    transform: scaleX(0);
  }

  .ng-dropdown-panel {
    //z-index: 0;
  }

  .mat-mdc-snack-bar-container {
    .mdc-snackbar {
      &__surface {
        padding: 0;
        box-shadow: none;
      }

      &__label {
        padding: 0;
      }
    }
  }
}
